import React from "react";
import StepFive from "../../components/cards/onboardingCard/stepFive";
import "./style.scss";
import { LiveChatWidget } from '@livechat/widget-react'

const BrandName = () => {
  return (
    <div className="bg bg-img">
      <div className="mid-card card-4">
        <StepFive />
      </div>
      {/* <LiveChatWidget
      license="14411064"
      visibility="minimized"
    /> */}
    </div>
  );
};

export default BrandName;
