import React, { Fragment, useEffect, useState } from "react";
import Title from "../../../text/title";
import Toaster from "../../../common/Toaster";
import LinkText from "../../../text/linkText";
import RoundedBtn from "../../../button/roundedBtn";
import "./style.scss";
import OptionCard from "../../optionCard";
import Input from "../../../input";
import NavRight from "../../../button/navRight";
import NavLeft from "../../../button/navLeft";
import { Link, navigate } from "gatsby";
import { connect } from "react-redux";
import { updateCurrentBrand as updateBrand } from "../../../../actions/brand/action";
import { brandAge as brandAgeConstant } from "../../../../util/constants";
import Lottie from "react-lottie-player";
import lottieJson from "../../../../../static/images/common/customer-form-lottie.json";

const StepFive = ({ updateBrand, age, brandId }) => {
 
  const [isMobile, setMobile] = useState(false);
  const [localstorageUserState, setLocalstorageUserState] = useState({});
  const [brandAge, setBrandAge] = useState("");
  const [refresh, doRefresh] = useState(0);
  const [toastrMsg, setToastrMsg] = useState("");

  useEffect(() => {
    if (window.innerWidth < 576) setMobile(true);
    const handleWindowSizeChange = () => {
      window.innerWidth < 576 ? setMobile(true) : setMobile(false);
    };

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);


  useEffect(() => {

    const localstorageUser = JSON.parse(localStorage.getItem("registeringUser"));
    setLocalstorageUserState(localstorageUser);
    setBrandAge(
      localstorageUser && localstorageUser.businessType ? localstorageUser.businessType : ""
      )

    if (!localstorageUser) {
     
      if(!localstorageUser || !localstorageUser.name){
        navigate("/onboarding/step-one");
      }
      else  if(!localstorageUser || !localstorageUser.state){
        navigateBack();
      }
     
    }
  }, []);

  const onSubmit = async () => {
    if (!brandAge) {
      setToastrMsg("Please choose!");
      doRefresh((prev) => prev + 1);
      return;
    }
    localstorageUserState.businessType = brandAge;
    localStorage.setItem(
      "registeringUser",
      JSON.stringify(localstorageUserState)
    );
    var updateBrandResonse = await updateBrand(brandId, { businessType: brandAge });
    if (updateBrandResonse && !updateBrandResonse.error) {
      navigate("/onboarding/brand-mission");
    } else {
      setToastrMsg("Unable to add Business type");
      doRefresh((prev) => prev + 1);
    }
  };

  const navigateBack = () => {
    navigate("/onboarding/business-category");
  };
  return (
    <Fragment>
      <Toaster refresh={refresh} type="error" message={toastrMsg} />
      <div className="onboarding row age">
        <div className="top">
          <img src="/images/common/plus_new_logo.svg" />
        </div>
        <div className="col-md-6 col-lg-6 col-sm-6 col-12 r-image">
          <div className="d-flex justify-content-center margin-top-img left-flex">
            {/* {isMobile} */}
            {!isMobile && (
              <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 200, height: 200, margin: "auto" }}
              />
            )}
            {isMobile && (
              <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 120, height: 120, margin: "auto" }}
              />
            )}
             <Title className="hide-mobile" text="Brand stage?" />
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-sm-6 col-12  step-content">
          <div className="brand-focus-heading hide-desktop">
            <Title text="Brand stage?" />
          </div>

          <div className="row option-spacing mob-spacing">
            <div className="col-6 col-sm-4 mid-option">
              <div
              className={`option-card option-med`}
              style={
                brandAge === 'Idea Stage'
                  ? { background: "#31ffea" }
                  : null
              }
              name="Idea Stage"
              onClick={() =>
                setBrandAge('Idea Stage')
              }
              >
                Idea Stage
                {/* <span className="opt-info">Less then 1yr</span> */}
              </div>
              
            </div>
            <div className="col-6 col-sm-4 mid-option">
              {/* <OptionCard
                style={
                  brandAge === "Emerging"
                    ? { background: "#31ffea" }
                    : null
                }
                name="Emerging"
                type="medium"
                onClick={() =>
                  setBrandAge("Emerging")
                }
              /> */}
              <div
              className={`option-card option-med`}
              style={
                brandAge === 'Startup'
                  ? { background: "#31ffea" }
                  : null
              }
              name="Startup"
              onClick={() =>
                setBrandAge('Startup')
              }
              >
                Start-up
                <span className="opt-info">1-2yrs</span>
              </div>
            </div>
          </div>
          <div className="row option-spacing mob-spacing">
            <div className="col-6 col-sm-4 mid-option">
              {/* <OptionCard
                style={
                  brandAge === "Boutique"
                    ? { background: "#31ffea" }
                    : null
                }
                name="Boutique"
                type="medium"
                onClick={() => setBrandAge("Boutique")}
              /> */}
               <div
              className={`option-card option-med`}
              style={
                brandAge === 'Emerging'
                  ? { background: "#31ffea" }
                  : null
              }
              name="Emerging"
              onClick={() =>
                setBrandAge('Emerging')
              }
              >
                Emerging
                <span className="opt-info">2yrs+</span>
              </div>
            </div>
            <div className="col-6 col-sm-4 mid-option">
              {/* <OptionCard
                style={
                  brandAge === "Corporate"
                    ? { background: "#31ffea" }
                    : null
                }
                name="Corporate"
                type="medium"
                onClick={() =>
                  setBrandAge("Corporate")
                }
              /> */}
               <div
              className={`option-card option-med`}
              style={
                brandAge === 'Established'
                  ? { background: "#31ffea" }
                  : null
              }
              name="Established"
              onClick={() =>
                setBrandAge('Established')
              }
              >
                Established
                <span className="opt-info">5yrs+</span>
              </div>
            </div>
          </div>
     
        <div className={!isMobile ? "row" : ""}>
          {/* <div className="col-12 col-md-6 col-sm-6 col-xl-6 to-right hpx"></div> */}
          <div className="col-12 margin-mobile to-left">
            <div className="nav-conatiner">
              <div>
                <span
                  className="text-decoration-none"
                  onClick={() => navigateBack()}
                >
                  <NavLeft />
                  <span className="nav-text">Back</span>
                </span>
              </div>
              <div>
                <span
                  className="text-decoration-none"
                  onClick={() => onSubmit()}
                >
                  <span className="nav-text">Next</span>
                  <NavRight />
                </span>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({ brand }) => ({
  age: brand.age,
  brandId: brand.brandId,
});

export default connect(mapStateToProps, { updateBrand })(StepFive);
